import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import CareerDetails from '../../components/CareerDetails';

export const query = graphql`
  query ($slug: String!) {
    wpJobPost(slug: { eq: $slug }) {
      title
      content
      slug
      jobPost {
        designation
        department
        nature
        formlink
      }
    }
  }
`;

export default function ContentPage(props) {
  return (
    <CareerDetails data={props.data.wpJobPost} location={props.location} />
  );
}

ContentPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};
