import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/layout';
import HeroTitle from '../components/HeroTitle';
import { CommonContext } from '../context';

function CareerDetails(props) {
  const postData = props.data;

  const renderHowToApplyJob = () => (
    <div>
      {postData.jobPost.formlink ? (
        <a
          className="text-xl text-secondary link-underline-white "
          href={postData.jobPost.formlink}
          target="__blank"
          rel="noreferrer"
        >
          Click here to apply
        </a>
      ) : (
        <div>
          <h2>How To Apply</h2>
          <div>
            Kindly send an email to <strong>hr[at]gtaf.org</strong> with your CV
            and a Cover Letter, answering the following questions:
            <ol>
              <li>Why do want to work at Greentech Apps? </li>
              <li>How do your skills and experience fit the role? </li>
            </ol>
            On the Subject of your email, please write: &quot;Application for{' '}
            {postData.jobPost.designation}&quot;. If your CV is shortlisted, you
            will get an email for an interview from us, in sha Allah.
            <br />
            <br />
            <a
              className="text-secondary link-underline-white"
              href={`mailto:hr@gtaf.org&subject=Application for ${postData.jobPost.designation}`}
            >
              Click here to apply
            </a>
          </div>
        </div>
      )}
    </div>
  );

  // TODO: This will be fixed when the volunteer data is available in new format
  // const renderHowToApplyVolunteer = () => (
  //   <div>
  //     <div>
  //       Assalamu &apos;alaykum wa rahmatullahi wa barakatuhu,
  //       <br />
  //       <br />
  //       JazakAllahu khairan for your interest in contributing to the cause of
  //       Greentech Apps Foundation. We sincerely appreciate your interest in
  //       working with us.
  //     </div>
  //     <h2>How To Apply</h2>
  //     <div>
  //       Kindly send an email to hr[at]gtaf.org with your Portfolio/CV, answering
  //       the following questions:
  //       <ol>
  //         <li>Why do you want to contribute to Greentech Apps?</li>
  //         <li>How do your skills and experience fit the role? </li>
  //       </ol>
  //       On the Subject of your email, please write: &quot;Volunteer Contribution
  //       for {postData.jobPost.designation}&quot;. <br />
  //       If your CV is shortlisted, you will get an email for an interview from
  //       us, in sha Allah.
  //       <br />
  //       BarakAllahu feekum.
  //       <br />
  //       <br />
  //       <a
  //         className="text-secondary link-underline-white"
  //         href={`mailto:hr@gtaf.org&subject=Volunteer Contribution as ${postData.jobPost.designation}`}
  //       >
  //         Click here to apply
  //       </a>
  //     </div>
  //   </div>
  // );

  return (
    <CommonContext.Provider value={{ location: props.location }}>
      <Layout title={postData.jobPost.designation} location={props.location}>
        <div className="px-6 py-16 bg-gradient-to-r from-primary-2 to-primary-1 md:py-24 md:px-0">
          <HeroTitle
            className="mx-auto md:text-center"
            text={postData.jobPost.designation}
          />
        </div>
        <div className="flex justify-center w-full markdown blog">
          <div className="w-full max-w-2xl px-6 py-16 md:px-0 md:py-24">
            <div dangerouslySetInnerHTML={{ __html: postData.content }} />
            {renderHowToApplyJob()}
          </div>
        </div>
      </Layout>
    </CommonContext.Provider>
  );
}

CareerDetails.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default CareerDetails;
